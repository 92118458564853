/* homepage.css */

@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400&display=swap'); /* Rounded font */

.homepage {
    font-family: 'Baloo 2', cursive;
    background-color: #f7efe6; 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 1050px;
}

.header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    background-color: #4a4a8a;
    color: white;
}

.header-icons {
    display: flex;
    gap: 20px;
}

.icon-grid, .icon-user {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 5px;
}

.hero {
    width: 100%;
    max-width: 12000px;
    text-align: center;
    padding: 20px;
    background-color: #f7efe6;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.hero-image {
    width: 100%;
    margin-top: 50px;
    max-height: 800px;
    max-width: 1700px;
    border-radius: 15px;
    margin-bottom: 20px;
    object-fit: cover;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.hero-title {
    font-size: 3rem;
    color: #7C93C3;
    margin-bottom: 0px;
}

.hero-subtitle {
    font-size: 2.5rem;
    color: #4a4a8a;
    margin-bottom: 50px;
    font-weight: bold;
}

.cta-button {
    background-color: #4a4a8a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    font-size: 1.2rem;
    cursor: pointer;
}

.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e1e1ef;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    max-width: 1500px;
    margin-top:0px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.search-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30%;
    margin-right: 40px;
}

.search-input label {
    font-size: 1rem;
    color: #4a4a8a;
    margin-bottom: 5px;
}

.search-input input,
.CitySearchAutocomplete-input {
    padding: 12px;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    width: 400px; /* Make all input boxes the same size */
    height: 50px;
    background-color: #fff; /* White background for location box */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.CitySearchAutocomplete-input {
    width: 80%; /* Consistent width with date inputs */
}

.search-input input:focus,
.CitySearchAutocomplete-input:focus {
    outline: none;
    border: 2px solid #4a4a8a;
    box-shadow: 0 0 8px rgba(74, 74, 138, 0.3);
}

.search-button {
    background-color: #4a4a8a;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.search-button:hover {
    background-color: #3a3a7a;
}
